/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useState } from 'react';

import { ChildrenType } from 'Type/Common.type';
import { fetchQuery } from 'Util/Request/Query';

import AmMWishlistsQuery from '../query/AmMWishlists.query';
import AmWishlistQuery from '../query/AmWishlist.query';

export const AmastyMultipleWishlistContext = createContext({
    wishlists: {},
    setWishlists: () => {},
    wislist: {},
    setWishlist: () => {},
    wishlistItem: {},
    setWishlistItem: () => {},
});

AmastyMultipleWishlistContext.displayName = 'AmastyMultipleWishlistContext';

/** @namespace Scandiweb/AmastyMultipleWishlist/Context/AmastyMultipleWishlist/AmastyMultipleWishlistProvider */
export const AmastyMultipleWishlistProvider = ({ children }) => {
    const [wishlists, setWishlists] = useState({
        items: [],
    });
    const [wishlist, setWishlist] = useState({
        items: [],
    });
    const [wishlistItem, setWishlistItem] = useState({});

    const fetchWishlists = async (activeTab, currentPage) => {
        const { wishLists } = await fetchQuery(AmMWishlistsQuery.getQuery(activeTab, currentPage));

        setWishlists(wishLists);
    };

    const fetchWishlist = async (wishlistId) => {
        const { wishlist } = await fetchQuery(AmWishlistQuery.getWishlistQuery(false, wishlistId));

        setWishlist(wishlist);
    };

    const value = {
        wishlists,
        fetchWishlists,
        wishlist,
        fetchWishlist,
        wishlistItem,
        setWishlistItem,
    };

    return (
         <AmastyMultipleWishlistContext.Provider value={ value }>
             { children }
         </AmastyMultipleWishlistContext.Provider>
    );
};

AmastyMultipleWishlistProvider.displayName = 'AmastyMultipleWishlistProvider';

AmastyMultipleWishlistProvider.propTypes = {
    children: ChildrenType.isRequired,
};
