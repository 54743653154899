/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyMultipleWishlist/Query/AmMWishlistConfig/Query */
export class AmMWishlistConfigQuery {
    getQuery() {
        return new Field('amastyMWishlistConfig')
            .addFieldList(this.getConfigFields());
    }

    getConfigFields() {
        return [
            'isEnabled',
            'socialNetworks',
        ];
    }
}

export default new AmMWishlistConfigQuery();
