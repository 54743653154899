/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Query } from '@tilework/opus';

import WishlistQuery from 'Query/Wishlist.query';
import { Field } from 'Util/Query';
/** @namespace Scandiweb/AmastyMultipleWishlist/Query/AmWishlist/Query */
export class AmWishlistQuery {
    getWishlistQuery(sharingCode, wishlistId) {
        const field = new Query('s_wishlist')
            .setAlias('wishlist')
            .addFieldList(WishlistQuery._getWishlistFields());

        if (sharingCode) {
            field.addArgument('sharing_code', 'ID', sharingCode);
        }

        if (wishlistId) {
            field.addArgument('wishlistId', 'Int', wishlistId);
        }

        return field;
    }

    getRemoveProductFromWishlistMutation(item_id, wishlistId) {
        return new Field('s_removeProductFromWishlist')
            .setAlias('removeProductFromWishlist')
            .addArgument('itemId', 'ID!', item_id)
            .addArgument('wishlistId', 'Int', wishlistId);
    }

    getClearWishlist(wishlistId) {
        return new Field('s_clearWishlist')
            .addArgument('wishlistId', 'Int', wishlistId)
            .setAlias('clearWishlist');
    }

    updateWishlistName(wishlistId, name) {
        return new Field('updateWishlistName')
            .addArgument('wishlistId', 'Int!', wishlistId)
            .addArgument('name', 'String!', name);
    }
}

export default new AmWishlistQuery();
