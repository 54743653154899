/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';
/** @namespace Scandiweb/AmastyMultipleWishlist/Query/AmMWishlists/Query */
export class AmMWishlistsQuery {
    getQuery(activeTab, currentPage) {
        const field = new Field('wishLists')
            .addFieldList(this.getWishlists());

        if (activeTab) {
            field.addArgument('activeTab', 'String', activeTab);
        }

        if (currentPage) {
            field.addArgument('currentPage', 'Int', currentPage);
        }

        return field;
    }

    getWishlists() {
        return [
            'activeTab',
            'emptyMessage',
            this.getItems(),
            'totalPages',
        ];
    }

    getItems() {
        return new Field('items')
            .addFieldList(this.getWishlistsFields());
    }

    getWishlistsFields() {
        return [
            'wishlistId',
            'name',
            'sharingCode',
            'images',
            'viewUrl',
            'isDeleteable',
            this.getSocialNetworks(),
        ];
    }

    getSocialNetworks() {
        return new Field('socialNetworksLinks')
            .addFieldList(this.getSocialNetworksFields());
    }

    getSocialNetworksFields() {
        return [
            'networkName',
            'url',
        ];
    }
}

export default new AmMWishlistsQuery();
