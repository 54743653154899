/**
 * Amasty Multiple Wishlist compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AmastyMultipleWishlistProvider } from '../../context/AmastyMultipleWishlist';

const addAmastyMultipleWishlistProvider = (member) => [
    (children) => (
         <AmastyMultipleWishlistProvider>
             { children }
         </AmastyMultipleWishlistProvider>
    ),
    ...member,
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addAmastyMultipleWishlistProvider,
        },
    },
};
