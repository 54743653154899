import { Mutation } from '@tilework/opus';

import {
    WishlistQuery as SourceWishlistQuery,
} from 'SourceQuery/Wishlist.query';
import {GQLShareWishlistInput} from 'Type/ShareWishlistGraphql.type';

/** @namespace Steinkrueger/Query/Wishlist/Query */
export class WishlistQuery extends SourceWishlistQuery {
    getShareWishlistMutation(input: GQLShareWishlistInput): Mutation<'shareWishlist', boolean> {
        return new Mutation<'s_shareWishlist', boolean>('s_shareWishlist')
            .setAlias('shareWishlist')
            .addArgument('input', 'ShareWishlistInput!', input);
    }
}

export default new WishlistQuery();
